import React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

import Layout from "../components/layout";
import Image from "../components/image";
import BackgroundVideo from "../components/backgroundVideo";
import TeamPhoto from "../components/teamPhoto";
import CardImage from "../components/cardImage";

import { 
  Container, 
  Motto, 
  Submotto, 
  MottoContainer,
  TitleContainer,
  Title, 
  Subtitle,
  SmallerCardsContainer,
  CardsContainer, 
  Card, 
  TeamContainer,
  TeamPhotoContainer,
  TeamTextContainer,
  TeamTitle,
  TeamDescription,
  ContactContainer,
  ContactTitle,
  ContactSpecsContainer,
  ContactHeading,
  ContactText,
  ContactLink,
  Copyright,
  CardLink,
  CardText,
  CenterCardText,
  TitleLink,
  MottoBackground
} from "../styles/IndexStyles";

const IndexPage = () => (
  <div>
    <Layout>
      <Helmet>
        <title>Abside Networks</title>
      </Helmet>
      <MottoContainer>
        <MottoBackground>
          <Motto>Go Long! with RF-Retina™ Technology<br />5G Coherent Massive MIMO AAU<br /></Motto>
          <Submotto>Realize 5G's Full Potential of Long Range and High Throughput with Multiple Beams<br />Available in both Commercial (FCC) and Government (NTIA / NATO Band IV) Spectrum<br />Full end-to-end 5G O-RAN compliant systems designed & built in the United States<br />Enabling Secure, Scalable, Fully Private 5G SA Networks</Submotto>
        </MottoBackground>
      </MottoContainer>
      <BackgroundVideo src="sf-pan-final.mp4" />
      <TitleContainer>
        <Title>HERMES 5G NR System</Title>
      </TitleContainer>
      <SmallerCardsContainer>
        <Card>
          <CardLink to="/5G-Products/#iris-m-n79">
            <CardImage filename="Iris-S-Card.png" />
            <CenterCardText>Iris AAU</CenterCardText>
          </CardLink>
        </Card>
        <Card>
          <CardLink to="/5G-Products/#hyperion-cudu">
            <CardImage filename="Hyperion-CUDU-Card.png" />
            <CenterCardText>Hyperion CU/DU</CenterCardText>
          </CardLink>
        </Card>
        <Card>
          <CardLink to="/5G-Products/#helios-5g-core">
            <CardImage filename="Helios-5GCore-Card.png" />
            <CenterCardText>Helios 5G Core SA</CenterCardText>
          </CardLink>
        </Card>
        <Card>
          <CardLink to="/5G-Products/#galerus-ue-omni">
            <CardImage filename="Galerus-UE-Omni-Card.png" />
            <CenterCardText>Galerus UE</CenterCardText>
          </CardLink>
        </Card>
      </SmallerCardsContainer>
      <CardsContainer>
        <Card>
          <CardLink to="/5G-Products/#narthex">
            <CardImage filename="narthex.jpg" />
            <CardText position="right"><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />Narthex Security</CardText>
          </CardLink>
        </Card>
        <Card>
          <CardLink to="/4G-LTE-Products/#rrh">
            <CardImage filename="rrh-card.png" />
            <CardText position="right"><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />Bastion RRH</CardText>
          </CardLink>
        </Card>
        <Card>
          <CardLink to="/4G-LTE-Products/#bpu">
            <CardImage filename="bastion-card.jpg" />
            <CardText position="right"><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />Bastion BPU</CardText>
          </CardLink>
        </Card>
        <Card>
          <CardLink to="/4G-LTE-Products/#ravelin">
            <CardImage filename="ravelin.jpg" />
            <CardText position="right"><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />Ravelin</CardText>
          </CardLink>
        </Card>
      </CardsContainer>
      <TeamContainer>
        <TeamPhotoContainer>
          <TeamPhoto className={undefined} />
        </TeamPhotoContainer>
        <TeamTextContainer>
          <TeamTitle>
            <TitleLink 
              href="https://www.linkedin.com/company/abside-networks-inc./people/" 
              target="_blank" 
              rel="noopener noreferrer">
              US Telecom Infrastructure Reborn
            </TitleLink>
          </TeamTitle>
          <TeamDescription>Abside Networks, Inc., headquartered in Concord, MA, creates innovative hardware & software networking products for large companies 
          and government organizations with a focus on 4G LTE & 5G and other RF technologies. The Abside Networks team is supported by multiple serial entrepreneurs 
          and includes: a principal investigator (PI) and multiple team members for the DARPA WNAN / AWNS revolutionary hardware platform as well as veterans from 
          large networking companies such as Nokia, Affirmed Networks, and Cisco. The team’s combined experience includes: RFIC & ASIC design, tunable filters, 
          antenna design, RF communication hardware component design, FPGA design, supported through an extensive embedded Linux development experience. The Abside 
          team also includes seasoned industry veterans from companies including IBM, Siemens, Yamaha, Cobham Defense and organizations such as MITRE and MIT Lincoln 
          Labs enabling full scale end-to-end ODM productization with KPI tracing. Our diverse team applies its wealth of experience to every aspect of our problem solving, 
          design, production, delivery, and life cycle support.</TeamDescription>
        </TeamTextContainer>
      </TeamContainer>
    </Layout>
    <ContactContainer>
      <ContactTitle>Contact</ContactTitle>
      <ContactSpecsContainer>
        <div>
          <ContactHeading>General:</ContactHeading>
          <ContactText><ContactLink href="mailto:contact@abside-networks.com">contact@abside-networks.com</ContactLink></ContactText>
        </div>
        <div>
          <ContactHeading>Careers:</ContactHeading>
          <ContactText>Openings: <ContactLink href="https://www.linkedin.com/company/abside-networks-inc./jobs/" target="_blank" rel="noopener noreferrer">Job Board</ContactLink></ContactText>
        </div>
        <div>
          <ContactHeading>Media:</ContactHeading>
          <ContactText><ContactLink href="mailto:media@abside-networks.com">media@abside-networks.com</ContactLink></ContactText>
        </div>
      </ContactSpecsContainer>
      <Copyright>Copyright © {new Date().getFullYear()} Abside Networks, Inc.</Copyright>
    </ContactContainer>
  </div>
);

export default IndexPage;
