import Styled from 'styled-components';
import { Link as GatsbyLink } from "gatsby";

export const Container = Styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 100vh;

  @media (max-width: 700px) {
    overflow-x: hidden;
  }
`;

export const TopBar = Styled.div`
  display: block;
  width: 100%;
`;

export const IconLogo = Styled.img`
  display: block;
  width: 300px;
  padding-top: 25px;
`;

export const Footer = Styled.footer`
  display: block;
  margin: 0 auto;
  width: 100%;
  font-family: 'Helvetica Neue Roman', sans-serif;
  font-size: 12px;
  color: #BBBABA;
  text-align: center;
`;

export const MottoContainer = Styled.div`
  display: flex;
  justify-content: left;
  align-items: flex-end;
  width: 100%;
  height: calc(100vh - 60px);
  z-index: 0;
  color: #494956;

    padding-left: 40px;
    padding-bottom: 40px;

  @media (max-width: 700px) {
    align-items: flex-end;
    padding-bottom: 60px;
    padding-left: 0;
    width: 80%;
    overflow: hidden;
    user-select: none;
  }
`;

export const MottoBackground = Styled.div`
  display: block;
  background-color: rgba(255, 255, 255, 1);
  padding: 10px 30px;
  border: 1px solid #CCCCCC;
`;

export const Motto = Styled.h1`
  display: block;
  position: relative;
  font-family: 'Helvetica Neue Roman', sans-serif;
  font-size: 30px;
  color: #494956;
  text-align: left;
  margin-top: 20px;
  padding: 0;
  letter-spacing: 0.5px;

  @media (max-width: 700px) {
    font-size: 28px;
    text-align: center;
    letter-spacing: -.25px;
    margin: 0;
    overflow: hidden;
  }
`;

export const Submotto = Styled(Motto)`
  font-family: 'Helvetica Neue Thin', sans-serif;
  font-size: 18px;
  margin-top: -22px;
  line-height: 24px;
  color: #666;
  letter-spacing: 0.5px;

  @media (max-width: 700px) {
    font-family: 'Helvetica Neue Light', sans-serif;
    font-size: 16px;
    text-align: center;
    letter-spacing: -.25px;
    line-height: 18px;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
`;

export const TitleContainer = Styled.div`
  display: block;
  position: relative;
  margin: 0 auto;
  width: 100%;
  clear: both;
  padding-top: 55px;

  @media (max-width: 700px) {
    user-select: none;
    overflow: hidden;
  }
`;

export const Title = Styled.h1`
  display: block;
  font-family: 'Helvetica Neue Roman', sans-serif;
  font-size: 26px;
  color: #494956;
  text-align: center;
  width: 50%;
  margin: 0 auto;
  letter-spacing: .75px;
  z-index: 100;
  position: relative;

  @media (max-width: 700px) {
    width: 80%;
    user-select: none;
    letter-spacing: -2px;
    overflow: hidden;
  }
`;

export const Subtitle = Styled(Title)`
  font-size: 16px;
  padding-top: 35px;
  font-family: 'Helvetica Neue Thin', sans-serif;
  letter-spacing: .5px;
  color: #666;
  line-height: 19px;

  @media (max-width: 700px) {
    width: 80%;
    user-select: none;
    letter-spacing: -0.5px;
    overflow: hidden;
  }
`;

export const CardsContainer = Styled.div`
  display: flex;
  height: 400px;
  width: 100%;
  position: relative;
  justify-items: center;
  align-items: center;
  justify-content: space-between;
  padding-top: 120px;

  @media (max-width: 700px) {
    display: block;
    padding-top: 50px;
    width: 95%;
    margin-left: 1.5%;
  }
`;

export const SmallerCardsContainer = Styled(CardsContainer)`
  padding-top: 0;
  height: 300px;
  position: relative;
  z-index: 0;

  @media (max-width: 700px) {
    padding-top: 0px;
  }
`;

export const Card = Styled.div`
  display: block;
  width: 23%;
  height: 400px;
  background-color: #eee;
  opacity: 1;
  transition: opacity .3s;

  &:hover {
    opacity: .8;
  }
  @media (max-width: 700px) {
    width: 48%;
    margin-left: 2%;
    margin-top: 2%;
    float: left;
  }
`;

export const TeamContainer = Styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 130px;

  @media (max-width: 700px) {
    flex-direction: column;
    padding-top: 60px;
  }
`;

export const TeamPhotoContainer = Styled.div`
  display: block;
  width: 37%;
  float: left;
  @media (max-width: 700px) {
    width: 85%;
  }
`;

export const TeamTextContainer = Styled.div`
  display: block;
  width: 60%;
  float: left;
  @media (max-width: 700px) {
    width: 85%;
  }
`;

export const TeamTitle = Styled(Title)`
  width: auto;
  text-align: left;
  font-family: 'Helvetica Neue Light', sans-serif;
  @media (max-width: 700px) {
    padding-top: 20px;
    padding-bottom: 12px;
    text-align: center;
    letter-spacing: -0.5px;
  }
`;

export const TeamDescription = Styled(Subtitle)`
  font-family: 'Helvetica Neue Thin', sans-serif;
  font-size: 15px;
  width: auto;
  text-align: left;
  line-height: 17px;
  padding-top: 10px;
  letter-spacing: .5px;
  @media (max-width: 700px) {
    letter-spacing: 0;
  }
`;

export const ContactContainer = Styled.div`
  display: block;
  height: 265px;
  margin-top: 70px;
  background-color: #383535;

  @media (max-width: 700px) {
    height: 100%;
  }
`;

export const ContactTitle = Styled(Title)`
  color: #fff;
  padding-top: 40px;

  @media (max-width: 700px) {
    padding-top: 30px;
  }
`;

export const ContactSpecsContainer = Styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding-right: 7px;
  padding-top: 15px;

  @media (max-width: 700px) {
    display: block;
  }
`;

export const ContactHeading = Styled(Subtitle)`
  color: #BBBABA;
  white-space: nowrap;
  text-align: center;
  width: auto;

  @media (max-width: 700px) {
    padding-top: 15px;
  }
`;

export const ContactText = Styled(Subtitle)`
  color: #BBBABA;
  white-space: nowrap;
  text-align: center;
  width: auto;
  padding-top: 10px;
`;

export const ContactLink = Styled.a`
  color: #BBBABA;
  white-space: nowrap;

  :hover {
    color: #ddd;
    transition: color .3s;
  }
`;

export const Copyright = Styled(ContactText)`
  padding-top: 70px;
  font-size: 13px;
  @media (max-width: 700px) {
    padding-bottom: 10px;
  }
  @media (max-width: 700px) {
    padding-top: 30px;
  }
`;

export const CardLink = Styled(GatsbyLink)`
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const CardText = Styled(Subtitle)`
  color: #fff;
  position: absolute;
  z-index: 100;
  width: auto;
  top: 50%;
  font-size: 18px;
  font-family: 'Helvetica Neue Roman', sans-serif;
  left: ${props => (props.position === 'left') ?
    '10%' : 'auto'
  };
  right: ${props => (props.position === 'left') ?
    'auto' : '5%'
  };
  transform: ${props => (props.position === 'left') ?
    'translate(-10%, -50%)' : 'translate(-5%, -50%)'
  };
  text-align: center;
`;

export const CenterCardText = Styled(Subtitle)`
  color: ${props => (props.color === 'white') ?
    '#fff' : '#494956'
  };
  position: absolute;
  z-index: 100;
  width: auto;
  top: 80%;
  font-size: 18px;
  font-family: 'Helvetica Neue Roman', sans-serif;
  left: 50%;
  transform: translate(-47%, 0%);
  text-align: center;
`;

export const TitleLink = Styled.a`
  color: #494956;

  :hover {
    color: #888;
    transition: color .3s;
  }
`;

