import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Styled from 'styled-components';
import Img from 'gatsby-image';

const TeamPhoto = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "abside-team.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <Img
          fluid={imageData}
        />
      )
    }}
  />
)

export default TeamPhoto;