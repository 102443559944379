import React from 'react';
import { graphql, StaticQuery, withPrefix } from 'gatsby';
import Styled from 'styled-components';

const BackgroundVideo = ({ src }) => (
  <div>
    <video
        autoPlay
        muted
        loop
        autobuffer
        playsInline
        style={{
            position: 'absolute',
            top: '60px',
            left: 0,
            bottom: 0,
            right: 0,
            width: '100vw',
            height: 'calc(100vh - 120px)',
            zIndex: '-1',
            objectFit: 'cover',
            objectPosition: 'center'
        }}
        src={withPrefix(src)}
    >
        <source src={withPrefix(src)} type="video/mp4" />
        Your device does not support playing 'video/mp4' videos
    </video>
  </div>
);

const StyledBackgroundVideo = Styled(BackgroundVideo)`
  width: 100%;
  height: calc(100vh - 120px);
  background-position: top center;
  background-repeat: repeat-y;
  background-size: cover;
  z-index: -1;
  display: block;
  position: absolute !important;
  top: 60px;
  left: 0;

  @media (max-width: 700px) {
    overflow: hidden;
  }
`;

export default StyledBackgroundVideo;